import apiClient from "@/api/api";

export const saveJwt = (userData: { jwt: string, username: string }) => {
    localStorage.setItem("tng-hochrhein-user", JSON.stringify(userData));
};

export const loadJwt = (): any => {
    const user = localStorage.getItem("tng-hochrhein-user");
    if (!user) {
        return null;
    } else {
        return JSON.parse(localStorage.getItem("tng-hochrhein-user") as string).jwt;
    }
};
export const loadUsername = (): any => {
    const user = localStorage.getItem("tng-hochrhein-user");
    if (!user) {
        return null;
    } else {
        return JSON.parse(localStorage.getItem("tng-hochrhein-user") as string).username;
    }
};
export const deleteJwt = () => {
    return localStorage.removeItem("tng-hochrhein-user")
};

export function parseJwt (token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function isTokenExpired (token: string) {
    const tok = parseJwt(token);
    return tok.exp < (Date.now() / 1000);
}


export function isAuthenticated() {
    const token = loadJwt();

    if (token && isTokenExpired(token)) {
        deleteJwt();
    }
    return !!loadJwt();
}

export async function login(username: string, password: string) {
    try{
        const res = await apiClient.post("/login", { username, password });
        if(res.status == 200){
            const userData = res.data;
            saveJwt(userData);
            return isAuthenticated();
        }else{
            console.error("POST login failed with response: ", res);
            return false;
        }
    } catch ( error: any ) {
        console.error("POST login FAILED", error);
        
        // error.response.status == 404
        return false;
    }    
}

export async function logout () {
    deleteJwt();
    window.location.reload();
}