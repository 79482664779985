import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '@/views/TabsPage.vue';
import { isAuthenticated } from '../api/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/login'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: 'login'
      },
      {
        path: 'login',
        component: () => import('@/views/LoginPage.vue')
      },
      {
        path: 'upload',
        component: () => import('@/views/UploadPage.vue'),
        meta: { requiresLoggedIn: true }
      },
      {
        path: 'result',
        component: () => import('@/views/ResultPage.vue'),
        meta: { requiresLoggedIn: true }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  console.log(" route from:", from.path, " to: ", to.path);

  if (to.meta.requiresLoggedIn && !isAuthenticated() && to.path !== '/tabs/login') {
    console.log("redirect to login");
    // redirect the user to the login page if he tries to access a page without authentication
    return '/tabs/login';

    // return false; // cancel navigation
  }
})

export default router;
